import { InvoiceInfoItem } from "../models/invoice";
import instance from "./interceptor";

const eInvoiceApiPath = process.env.REACT_APP_ENV !== "development" ? '/einvoice-api' : '';
const apiPath = process.env.REACT_APP_ENV !== "development" ? '/api' : '';
export const getInvoiceList = async (data?: any) => {
  try {
    const response = await instance.get(`${eInvoiceApiPath}/invoice`, { params: data });
    return response.data;
  } catch (error) {
    console.error("error:", error);
    throw error;
  }
};

export const uploadInvoice = async (data: any) => {
  try {
    const response = await instance.post(`${eInvoiceApiPath}/invoice`, data);
    return response.data;
  } catch (error) {
    console.error("error:", error);
    throw error;
  }
};

export const getInvoiceInfoList = async (data?: any) => {
  try {
    const response = await instance.get(`${apiPath}/guests`, { params: data });
    return response.data;
  } catch (error) {
    console.error("error:", error);
    throw error;
  }
};

export const createInvoiceInfo = async (data?: InvoiceInfoItem) => {
  try {
    const response = await instance.post(`${apiPath}/guests`, data);
    return response.data;
  } catch (error) {
    console.error("error:", error);
    throw error;
  }
};

export const deleteInvoiceInfo = async (id: string) => {
  try {
    const response = await instance.delete(`${apiPath}/guests/${id}`);
    return response.data;
  } catch (error) {
    console.error("error:", error);
    throw error;
  }
};

