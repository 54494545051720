import React from "react";
import {
  Button,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerHeader,
  DrawerBody,
  DrawerFooter,
  Table,
  useColorModeValue,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  Text,
} from "@chakra-ui/react";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import "react-datetime/css/react-datetime.css";
import { InvoiceItem, IssueItem } from "../../../../../models/invoice";


interface EditFormProps {
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
  initialValues: InvoiceItem;
}

const InvoiceDrawer: React.FC<EditFormProps> = ({
  isOpen,
  onOpen,
  initialValues,
  onClose,
}) => {
  const { t } = useTranslation();
  const textColor = useColorModeValue("gray.700", "white");
  const tableFiled = [
    t("invoice.fields.invoice_detail.project_name"),
    t("invoice.fields.invoice_detail.type"),
    t("invoice.fields.invoice_detail.unit"),
    t("invoice.fields.invoice_detail.quantity"),
    t("invoice.fields.invoice_detail.unit_price"),
    t("invoice.fields.invoice_detail.amount"),
    t("invoice.fields.invoice_detail.tax_rate"),
    t("invoice.fields.invoice_detail.tax"),
  ];

    const _renderCell = (
      row: IssueItem,
      text: any,
      fontSize: string,
      fontWeight: string,
      extraProps?: any
    ) => {
      return (
        <Td
          py="0.5rem"
          {...extraProps}
          pl="0.2rem"
        >
          <Text fontSize={fontSize} fontWeight={fontWeight}>
            {text}
          </Text>
        </Td>
      );
    };
  const _renderTableRow = (row: IssueItem, index: number) => {
    return (
      <Tr
        key={index}
        _hover={{ bg: "green.200", cursor: "pointer", color: "white" }}
      >
        {_renderCell(row, row.ItemName, "sm", "bold", { minW: "200px" })}
        {_renderCell(row, row.SpecMod, "sm", "bold", { minW: "200px" })}
        {_renderCell(row, row.MeaUnits?.replace("T", " "), "sm", "normal")}
        {_renderCell(row, row.Quantity, "md", "normal")}
        {_renderCell(row, row.UnPrice, "md", "normal")}
        {_renderCell(row, row.Amount, "md", "normal")}
        {_renderCell(row, row.TaxRate, "md", "normal")}
        {_renderCell(row, row.ComTaxAm, "md", "normal")}
      </Tr>
    );
  };

  return (
    <Drawer
    isOpen={isOpen}
    placement='bottom'
    size="lg"
    onClose={onClose}
  >
    <DrawerOverlay />
    <DrawerContent>
      <DrawerCloseButton />
      <DrawerHeader>{t("menu.invoice-management")}</DrawerHeader>

      <DrawerBody>
      <Table variant="simple" color={textColor}>
          <Thead>
            <Tr my=".8rem" pl="0px" color="gray.400">
              {tableFiled.map((caption, idx) => {
                return (
                  <Th color="gray.400" key={idx} ps={idx === 0 ? "0px" : ""}>
                    {caption}
                  </Th>
                );
              })}
            </Tr>
          </Thead>
          <Tbody>
            {initialValues?.issuItemInformations.map((row: IssueItem, index) => {
              return _renderTableRow(row, index);
            })}
          </Tbody>
        </Table>
      </DrawerBody>

      <DrawerFooter>
        <Button colorScheme='blue' onClick={onClose}>{t('common.btn.close')}</Button>
      </DrawerFooter>
    </DrawerContent>
  </Drawer>
  );
};

export default InvoiceDrawer;
